/**
 * External Imports
 */
import { FC, useMemo, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { format, differenceInDays } from 'date-fns';

import { NeuTableRow, NeuContainer } from '@neutron/react';
/**
 * Internal Imports
 */
import { MarkCompleteBtn } from '../../shared/buttons';
import Table from '../../shared/table';

import store, { RootState } from '../../../redux/store';
import {
  openModal,
  setModalData,
  setModalType
} from '../../../redux/actions/Modal.action';
import {
  clearIssueFilter,
  setTaskSort
} from '../../../redux/actions/Task.action';

import { useIssuesApi } from '../../../utils/api';
import { useDebounceValue } from '../../../utils/debouncers';
import { date, abbreviatedName } from '../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import { AuthUserProps, Task, Filter } from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  CategoryContainer,
  CategoryLabel,
  CategoryText,
  ServiceCentralLogo,
  CategoryIndent,
  StyledBoldLabel,
  StyledLabel,
  StyledTableHeading,
  TextContainer
} from '../../shared/table/Table.styles';
/**
 * Static File Imports
 */
import ServiceCentralIcon from '../../../assets/images/service_central_icon.png';
import { useUserRolesRef } from '../../../services/UserRoles';

interface IIssueTableProps {
  authorizedUser: AuthUserProps;
  location: string;
  units: { unitId: string; unit: string }[];
  searchTerm: string;
  selectedUnits: { id: string; unit: string }[];
  storeIssues: Task[];
  issueFilter: Filter;
  serviceCentralCategories: string[];
  totalCount: number;
  currentIssuePage: number;
  selectedDelegate: string;
  loading: boolean;
}

const IssuesTable: FC<IIssueTableProps> = ({
  authorizedUser,
  location,
  units,
  searchTerm,
  selectedUnits,
  storeIssues,
  issueFilter,
  serviceCentralCategories,
  totalCount,
  currentIssuePage,
  selectedDelegate,
  loading
}) => {
  const dispatch = useDispatch();
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('desc');
  const [sortKey, setSortKey] = useState<string>('created');
  const [active, setActive] = useState<string>('Created');

  const { isEnterpriseAdmin } = useUserRolesRef();

  const dbSearchTerm = useDebounceValue(searchTerm.trim(), 350);

  const isIssueTableEmpty = useMemo(
    () => !dbSearchTerm && storeIssues.length === 0,
    [dbSearchTerm, storeIssues]
  );

  const isIssueTableSearchEmpty = useMemo(
    () => dbSearchTerm && storeIssues.length === 0,
    [dbSearchTerm, storeIssues]
  );

  // Reset page when navigate away
  useEffect(() => {
    return () => {
      dispatch(clearIssueFilter());
      dispatch(setTaskSort({ sortKey: 'created', sortDir: 'desc' }));
    };
  }, []);

  useEffect(() => {
    const issuesAction = useIssuesApi({
      authorizedUser,
      selectedDelegate,
      selectedUnits,
      currentIssuePage,
      issueFilter,
      sortDir,
      sortKey,
      searchTerm: dbSearchTerm && dbSearchTerm.length > 2 ? dbSearchTerm : ''
    });
    store.dispatch(issuesAction);
  }, [
    authorizedUser,
    selectedDelegate,
    selectedUnits,
    currentIssuePage,
    issueFilter,
    sortDir,
    sortKey,
    dbSearchTerm
  ]);

  useEffect(() => {
    dispatch(setTaskSort({ sortKey, sortDir }));
  }, [sortDir, sortKey]);

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const openIssue = (task: Task) => {
    if (
      serviceCentralCategories.includes(task.category) ||
      (task.taskSubtype && serviceCentralCategories.includes(task.taskSubtype))
    ) {
      dispatch(setModalType('View Issue'));
    } else if (
      isEnterpriseAdmin ||
      (task.userId.toLowerCase() === authorizedUser.hcaid.toLowerCase() &&
        !task.completed &&
        task.taskDate &&
        !date.isOverOneDayOld(task.taskDate))
    ) {
      dispatch(setModalType('Edit Issue'));
    } else {
      dispatch(setModalType('Edit Issue'));
    }
    dispatch(setModalData(task));
    dispatch(openModal());
  };

  const issues = useMemo(() => {
    if (location === 'patient') {
      return storeIssues.map((issue: Task) => ({
        ...issue,
        unit: units.find(unit => unit.unitId === issue.unitId)?.unit
      }));
    }
    return storeIssues;
  }, [storeIssues, selectedUnits]);

  const colsForPatient =
    '{"Created":"13%", "Location":"10%","Issue Category":"15%","Description":"16%","Patient":"9%","Last Comment":"17%","Attached to":"8%","Status": "12%"}';
  const colsForEmployee =
    '{"Created":"13%", "Location":"10%","Issue Category":"15%","Description":"16%","Employee":"9%","Last Comment":"17%","Attached to":"8%","Status": "12%"}';

  const headers: JSX.Element[] = [
    <StyledTableHeading
      id="Issue-Table-Created-Column"
      key="Created"
      slot="Created"
      icon={
        !(active === 'Created') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('created', 'Created')}
      active={active === 'Created'}
    >
      Created
    </StyledTableHeading>,
    <StyledTableHeading
      id="Issue-Table-Location-Column"
      key="Location"
      slot="Location"
      icon={
        !(active === 'Location') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('location', 'Location')}
      active={active === 'Location'}
    >
      {location === 'patient' ? 'Location' : 'Department'}
    </StyledTableHeading>,
    <StyledTableHeading
      id="Issue-Table-Category-Column"
      key="Issue Category"
      slot="Issue Category"
      style={{ marginLeft: '20px' }}
      icon={
        !(active === 'Issue Category')
          ? 'asc'
          : sortDir === 'asc'
          ? 'desc'
          : 'asc'
      }
      onClick={() => sortByHeading('category', 'Issue Category')}
      active={active === 'Issue Category'}
    >
      Category/Subcategory
    </StyledTableHeading>,
    <StyledTableHeading
      id="Issue-Table-Description-Column"
      key="Description"
      slot="Description"
      icon="none"
    >
      Description
    </StyledTableHeading>
  ];
  if (location === 'patient') {
    headers.push(
      <StyledTableHeading
        id="Issue-Table-Patient-Column"
        key="Patient"
        slot="Patient"
        icon={
          !(active === 'Patient') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
        }
        onClick={() => sortByHeading('patientName', 'Patient')}
        active={active === 'Patient'}
      >
        Patient
      </StyledTableHeading>,
      <StyledTableHeading
        id="Issue-Table-Update-Column"
        key="Last Comment"
        slot="Last Comment"
        icon={
          !(active === 'Last Comment')
            ? 'asc'
            : sortDir === 'asc'
            ? 'desc'
            : 'asc'
        }
        onClick={() => sortByHeading('lastComment', 'Last Comment')}
        active={active === 'Last Comment'}
      >
        Last Comment
      </StyledTableHeading>
    );
  } else {
    headers.push(
      <StyledTableHeading
        id="Issue-Table-Employee-Column"
        key="Employee"
        slot="Employee"
        icon={
          !(active === 'Employee') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
        }
        onClick={() => sortByHeading('employeeName', 'Employee')}
        active={active === 'Employee'}
      >
        Employee
      </StyledTableHeading>,
      <StyledTableHeading
        id="Issue-Table-Comment-Column"
        key="Last Comment"
        slot="Last Comment"
        icon={
          !(active === 'Last Comment')
            ? 'asc'
            : sortDir === 'asc'
            ? 'desc'
            : 'asc'
        }
        onClick={() => sortByHeading('lastComment', 'Last Comment')}
        active={active === 'Last Comment'}
      >
        Last Comment
      </StyledTableHeading>
    );
  }
  headers.push(
    <StyledTableHeading
      id="Issue-Table-Attached-Column"
      key="Attached to"
      slot="Attached to"
      icon={
        !(active === 'Attached to') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('attachedTo', 'Attached to')}
      active={active === 'Attached to'}
    >
      Attached
    </StyledTableHeading>,
    <StyledTableHeading
      id="Issue-Table-Status-Column"
      key="Status"
      slot="Status"
      icon={!(active === 'Status') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'}
      onClick={() => sortByHeading('status', 'Status')}
      active={active === 'Status'}
    >
      Status
    </StyledTableHeading>
  );

  const rows = issues.map((issue: Task) => (
    <NeuTableRow
      id={`Issue-Row-${issue.taskId}`}
      key={issue.taskId}
      columns={location === 'patient' ? colsForPatient : colsForEmployee}
      size="large"
      onClick={() => openIssue(issue)}
      color={issue.completed ? 'gray-0' : 'plain-0'}
    >
      <NeuContainer className="h-100 px-0" slot="Created">
        <StyledBoldLabel>{issue.friendlyId}</StyledBoldLabel>
        <StyledBoldLabel color="gray-90" style={{ fontWeight: 600 }}>
          {issue.userFullName}
        </StyledBoldLabel>
        <StyledLabel>
          {issue.taskDate ? date.dayOfYrFull(issue.taskDate) : ''}
        </StyledLabel>
        {!issue.completed && (
          <StyledLabel>
            {issue.taskDate && date.isOrbitTimestamp(issue.taskDate)
              ? `${differenceInDays(
                  new Date(),
                  date.parseDate(issue.taskDate)
                )}d`
              : ''}
          </StyledLabel>
        )}
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Location">
        <StyledBoldLabel key={issue.unitId} color="gray-90">
          {location === 'patient'
            ? issue.unit
            : issue.department
            ? issue.department
            : 'Unknown'}
        </StyledBoldLabel>
        {location === 'patient' && (
          <StyledLabel>
            {issue.room ? `${issue.room}${issue.bed || ''}` : ''}
          </StyledLabel>
        )}
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Issue Category">
        <CategoryContainer>
          <CategoryLabel color="gray-90">
            {serviceCentralCategories.includes(issue.category) ||
            serviceCentralCategories.includes(issue.taskSubtype) ? (
              <ServiceCentralLogo
                width="25px"
                alt="service central logo"
                src={ServiceCentralIcon}
              />
            ) : (
              <CategoryIndent />
            )}
          </CategoryLabel>
          <TextContainer>
            <CategoryText>{issue.category || issue.taskSubtype}</CategoryText>
            <StyledLabel>{issue.subCategory}</StyledLabel>
          </TextContainer>
        </CategoryContainer>
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Description">
        <StyledLabel className="text-clamp text-clamp__3" color="gray-90">
          {issue.description}
        </StyledLabel>
      </NeuContainer>
      {location === 'patient' ? (
        <StyledBoldLabel color="gray-90" slot="Patient">
          {issue.patientFullName === ' ' || null
            ? issue.patientFullName
            : abbreviatedName(issue.patientFirstName, issue.patientLastName)}
        </StyledBoldLabel>
      ) : (
        <StyledBoldLabel color="gray-90" slot="Employee">
          {issue.employeeFullName}
        </StyledBoldLabel>
      )}
      <NeuContainer className="h-100 px-0" slot="Last Comment">
        {issue.discussionId && issue.latestComment ? (
          <>
            <StyledLabel color="gray-90">
              {issue.latestComment.date &&
              date.isOrbitTimestamp(issue.latestComment.date) &&
              issue.latestComment.authorFullName
                ? `${format(
                    date.parseDate(issue.latestComment.date),
                    'MM/dd/yyyy'
                  )} | ${issue.latestComment.authorFullName}`
                : ''}
            </StyledLabel>
            <StyledLabel className="text-clamp text-clamp__3" color="gray-90">
              {issue.latestComment.body ? issue.latestComment.body : ''}
            </StyledLabel>
          </>
        ) : (
          'No Comments'
        )}
      </NeuContainer>
      <StyledBoldLabel color="primary" slot="Attached to">
        {issue.stoplightId ? 'Stoplight' : null}
      </StyledBoldLabel>
      <NeuContainer className="h-100 px-0" slot="Status">
        <StyledBoldLabel className="mb-2" color="gray-90">
          {issue.completed ? 'Completed' : 'Opened'}
        </StyledBoldLabel>
        {issue.completed && (
          <StyledLabel>
            {issue.completedDate &&
              date.isOrbitTimestamp(issue.completedDate) &&
              format(date.parseDate(issue.completedDate), 'MM-dd-yyyy')}
          </StyledLabel>
        )}
        {!issue.completedDate &&
        !(
          serviceCentralCategories.includes(issue.category) ||
          serviceCentralCategories.includes(issue.taskSubtype)
        ) ? (
          <MarkCompleteBtn taskId={issue.taskId} taskType="issue" />
        ) : (
          ''
        )}
      </NeuContainer>
    </NeuTableRow>
  ));

  return (
    <Table
      cols={location === 'patient' ? colsForPatient : colsForEmployee}
      headers={headers}
      rows={rows}
      tableType="Issue"
      totalResults={totalCount}
      isIssueTableEmpty={isIssueTableEmpty}
      isIssueTableEmptySearch={isIssueTableSearchEmpty}
      loading={loading}
    />
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser?.authorizedUser,
  units: state.ConfigReducer.units,
  selectedUnits: state.ConfigReducer.selectedUnits,
  storeIssues: state.TaskReducer.issues,
  location: state.UserReducer.userSection,
  issueFilter: state.TaskReducer.issueFilter,
  serviceCentralCategories: state.ConfigReducer.serviceCentralCategories,
  totalCount: state.TaskReducer.totalCount,
  currentIssuePage: state.TaskReducer.currentIssuePage,
  selectedDelegate: state.EmployeeReducer.selectedDelegatedEmployee.hcaid,
  loading: state.TaskReducer.loading
});

export default connect(mapReduxStateToProps)(IssuesTable);
